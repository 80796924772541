import React from 'react';
import {getStatusFeatureSelectOptions, updateBundleStatus} from '../../../services/kooper';
import _ from 'lodash';
import {logEvent} from '../../../utils/firebase';
import {BaseModalStage, MuiBaseModal} from '../MuiBaseModal';
import {Autocomplete, Button, Checkbox, FormControlLabel, Grid, TextField, Tooltip, Typography} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Props {
  updateBundlePage: Function,
  t: Function,
  bundleId: number,
  data: any,
  qms: any
}

interface State {
  open: boolean,
  stage: BaseModalStage,
  shippingProviderSelectOptions: Array<any>,
  packageTypeSelectOptions: Array<any>,
  shippingNumber: string,
  otherProvider: string,
  documentsUploadedCheck: boolean,
  selectedPackageType: string,
  selectedShippingProvider: string
}

export class ShippedModal extends React.Component <Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      stage: 'content',
      shippingProviderSelectOptions: [],
      packageTypeSelectOptions: [],
      shippingNumber: '',
      otherProvider: '',
      selectedShippingProvider: '',
      selectedPackageType: '',
      documentsUploadedCheck: false,
    };
  }

  componentDidMount() {
    Promise.all([
      getStatusFeatureSelectOptions('shipping_provider'),
      getStatusFeatureSelectOptions('package_type'),
    ])
      .then(response => {
        // Move Other reason to the end of the array, and sort the remaining keys alphabetically
        const shippingProviderSelectOptions = response[0].data.statusFeatureSelectOptions.sort((a: any, b: any) => {
          if (a.selectOptionKey === 'other') return 1;
          if (b.selectOptionKey === 'other') return -1;
          return this.props.t(`shippingProviders:${a.selectOptionKey}`).localeCompare(this.props.t(`shippingProviders:${b.selectOptionKey}`));
        });

        const packageTypeSelectOptions = response[1].data.statusFeatureSelectOptions;
        this.setState({
          shippingProviderSelectOptions: shippingProviderSelectOptions,
          packageTypeSelectOptions: packageTypeSelectOptions,
        });
      });
  }

  checkForOrderConfirmation = (): boolean => {
    return this.props.data.bundleAttachments.some((x: any) => x.kaktusAttachment.attachmentType.key === 'order_confirmation');
  };

  hasMissingQmsDocuments = (): boolean => {
    const bopcWithoutDocs = this.props.data.bundleOrderedPartConfigs.map(bopc => {
      return _.isEqual(bopc.requiredDocuments.sort(), bopc.uploadedDocuments.sort());
    });
    return bopcWithoutDocs.some(x => x === false);
  };

  handleSubmit = () => {
    const {selectedShippingProvider, selectedPackageType, shippingNumber, otherProvider} = this.state;
    this.setState({stage: 'loading'});
    const data = [
      {key: 'shipping_provider', value: selectedShippingProvider},
      {key: 'package_type', value: selectedPackageType},
      {key: 'shipping_number', value: shippingNumber},
    ];
    updateBundleStatus(this.props.bundleId, 'shipped', data, {other: otherProvider})
      .then(() => {
        logEvent('bundle_shipped');
        this.setState({stage: 'success'});
      })
      .catch(() => this.setState({stage: 'failure'}));
  };

  handleToggle = () => {
    if (this.state.open && this.state.stage === 'success') {
      this.props.updateBundlePage();
    }
    this.setState({
      open: !this.state.open,
    });
  };

  content = () => {
    const {t} = this.props;
    return (
      <Grid container rowSpacing={5}>
        <Grid item container xs={12} spacing={2} height={'150px'} alignContent={'flex-start'}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{t('modals:shipped_modal_shipping_details')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              renderInput={(props) => <TextField {...props} size={'small'} label={t('modals:shipped_modal_packaging_type')} />}
              onChange={(e, value) => this.setState({selectedPackageType: value?.selectOptionKey})}
              getOptionLabel={x => t(`modals:shipped_modal_${x.selectOptionKey}`)}
              options={this.state.packageTypeSelectOptions}
            />
          </Grid>
          <Grid item container xs={6} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                renderInput={(props) => <TextField {...props} size={'small'} label={t('modals:shipped_modal_shipping_provider')} />}
                onChange={(e, value) => this.setState({selectedShippingProvider: value?.selectOptionKey})}
                getOptionLabel={x => t(`shippingProviders:${x.selectOptionKey}`)}
                options={this.state.shippingProviderSelectOptions}
              />
            </Grid>
            {this.state.selectedShippingProvider === 'other' &&
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size={'small'}
                  label={t('modals:shipping_modal_other_provider')}
                  onChange={(e) => this.setState({otherProvider: e.target.value})}
                />
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{t('modals:shipped_modal_input_tracking_number')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(e) => this.setState({shippingNumber: e.target.value})}
              fullWidth size={'small'}
              label={t('modals:shipped_modal_tracking_number')}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{t('modals:shipped_modal_qms_check')}</Typography>
          </Grid>
          <Grid item container xs={12}>
            <FormControlLabel
              control={<Checkbox checked={this.state.documentsUploadedCheck} onChange={() => this.setState({documentsUploadedCheck: !this.state.documentsUploadedCheck})} />}
              label={t('modals:shipped_modal_all_documents_uploaded')}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  footer = () => {
    return (
      <Grid item xs={12} container justifyContent="flex-end" alignItems="flex-end" height={'100%'}>
        <Grid item xs={6} />
        <Grid item xs={6} style={{justifyContent: 'flex-end', display: 'flex', paddingRight: 20, paddingBottom: 20}}>
          <Button
            disabled={
              !this.state.selectedShippingProvider ||
              this.state.selectedShippingProvider === 'other' && !this.state.otherProvider ||
              !this.state.selectedPackageType ||
              !this.state.shippingNumber.trim() ||
              !this.state.documentsUploadedCheck
            }
            onClick={() => this.handleSubmit()}
            endIcon={<ArrowForwardIosIcon fontSize={'small'} />}
          >
            {this.props.t('modals:shipped_modal_shipped_button')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {t} = this.props;
    return (
      <MuiBaseModal
        stage={this.state.stage}
        button={
          <Tooltip
            title={!this.checkForOrderConfirmation() ? t('modals:shipped_modal_missing_order_confirmation') : ''}
          >
            <span> {/* Needed to display tooltip on disabled button*/}
              <Button
                onClick={() => this.setState({open: true})}
                fullWidth
                size={'small'}
                disabled={!this.checkForOrderConfirmation()}
                sx={{'&.MuiButton-root:disabled': {backgroundColor: '#00AFD345', color: '#FFF'}}}
              >
                {t('bundledetailspage:bs_action_panel_button_ready_to_ship')}
              </Button>
            </span>
          </Tooltip>
        }
        onToggle={() => this.handleToggle()}
        open={this.state.open}
        content={this.content()}
        height={'80vh'}
        successMessageTitle={t('modals:shipped_modal_success_message')}
        errorMessageTitle={t('modals:shipped_modal_failure_message')}
        header={this.state.stage === 'content' && t('modals:shipped_modal_title')}
        footer={this.state.stage === 'content' ? this.footer() : <></>}
      />
    );
  }
}
